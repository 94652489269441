<template>
  <div class="text-editor-widget">
    <textarea @change="changeTextArea" id="editor" name="editor"></textarea>
  </div>
</template>

<script>
import "jodit/build/jodit.min.css";
import { Jodit } from "jodit";

export default {
  name: "TextEditorWidget",
  mixins: [],
  props: {
    unique: {
      type: [String, Number]
    },
    editValue: {
      type: String
    }
  },
  components: {},
  data() {
    return {
      value: "",
      preview: "",
      editor: null
    };
  },
  mounted() {
    this.editor = new Jodit("#editor", {
      language: "ru",
      uploader: {
        insertImageAsBase64URI: true
      },
      buttons: [
        "paragraph",
        "bold",
        "italic",
        "strikethrough",
        "underline",
        "|",
        "image",
        "table",
        "|",
        "left",
        "center",
        "right",
        "justify",
        "|",
        "ul",
        "ol",
        "|",
        "brush"
      ]
    });
    if (this.editValue) {
      this.value = this.editValue;
      this.editor.value = this.editValue;
    }
  },
  methods: {
    changeTextArea() {
      this.value = this.editor.value;
      this.preview = this.editor.text.trim();
      this.$root.$emit(`text-editor:change_${this.unique}`, {
        value: this.value,
        preview: this.preview
      });
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";

.text-editor-widget {
  width: 100%;
  /deep/ {
    .jodit {
      flex-shrink: 0 !important;
      width: 100% !important;
      height: 85vh !important;
    }

    .jodit-container:not(.jodit_inline) {
      border-radius: 10px;
      border: 1px solid $color-contour;
      background: #ffffff;
    }

    .jodit-toolbar__box {
      color: $color-text-secondary;

      border-radius: 10px 10px 0 0;
      background-color: $color-background;
      border: none;
    }

    .jodit-ui-separator {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 14px;
      width: 1px;
      margin-top: 12px;
      margin-left: 5px;

      border: none;
      background-color: $color-text-secondary;
    }

    .jodit-workplace {
      height: 75vh !important;

      @media screen and (max-width: 850px) and (max-height: 450px) {
        height: 50vh !important;
      }

      @media screen and (max-width: 568px) and (max-height: 450px) {
        height: 50vh !important;
      }

      .jodit-wysiwyg {
        word-break: break-word;
      }
    }

    .jodit-placeholder {
      display: none !important;
    }

    .jodit-status-bar {
      display: none;
      border-color: $color-contour;
      background-color: $color-background;
    }

    .jodit-status-bar__item {
      display: none;
    }

    .jodit-status-bar__item-right {
      display: block;

      font-size: 13px;
      line-height: 13px;
      font-weight: 400;
      color: $color-text-secondary;
    }
  }
}
</style>
